import { Center, createStyles, Divider, Flex, Navbar, Stack, UnstyledButton } from '@mantine/core'
import {
    IconCalendarCheck,
    IconFileAnalytics,
    IconLogout,
    IconNews,
    IconShieldCheck,
    IconUserCircle,
    IconWallet,
} from '@tabler/icons-react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { signOut } from 'aws-amplify/auth'
import { PRIVACY_LINK, TERMS_LINK, Tooltip } from 'emporia-shared-frontend'

const useStyles = createStyles((theme) => ({
    link: {
        width: 50,
        height: 50,
        borderRadius: theme.radius.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.white,
        opacity: 0.85,

        '&:hover': {
            opacity: 1,
            backgroundColor: theme.fn.lighten(theme.colors[theme.primaryColor][9], 0.1),
        },
    },

    active: {
        opacity: 1,
        '&, &:hover': {
            backgroundColor: theme.fn.lighten(theme.colors[theme.primaryColor][9], 0.15),
        },
    },
}))

interface NavbarLinkProps {
    icon: React.FC<any>
    label: string
    active?: boolean

    onClick?(): void
}

function NavbarLink({ icon: Icon, label, active, onClick }: NavbarLinkProps) {
    const { classes, cx } = useStyles()
    return (
        <Tooltip label={label} position='right' transitionProps={{ duration: 0 }}>
            <UnstyledButton onClick={onClick} className={cx(classes.link, { [classes.active]: active })}>
                <Icon size='1.2rem' stroke={1.5} />
            </UnstyledButton>
        </Tooltip>
    )
}

const routes = [
    { icon: IconFileAnalytics, label: 'Projects', route: '/' },
    { icon: IconWallet, label: 'Billing', route: '/billing' },
    {
        icon: IconUserCircle,
        label: 'Account Settings',
        route: '/accountsettings',
    },
]

export default function CustomNavbar() {
    const router = useRouter()

    const links = routes.map((link, index) => (
        <NavbarLink
            {...link}
            key={link.label}
            active={router.pathname === link.route}
            onClick={() => {
                router.push(link.route)
            }}
        />
    ))

    return (
        <Flex sx={{ width: 80, zIndex: 100 }}>
            <Navbar
                height={'100%'}
                width={{ base: 80 }}
                p='md'
                sx={(theme) => ({
                    backgroundColor: theme.colors[theme.primaryColor][9],
                    marginRight: 0,
                    position: 'fixed',
                    zIndex: 100,
                })}
            >
                <Center>
                    <Image
                        alt='Emporia Research Logo'
                        height={30}
                        width={30}
                        priority={true}
                        src={'/images/EmporiaLetterE.png'}
                    />
                </Center>
                <Navbar.Section grow mt={40}>
                    <Stack justify='center' spacing={0}>
                        {links}
                    </Stack>
                </Navbar.Section>
                <Navbar.Section>
                    <NavbarLink
                        icon={IconNews}
                        label='Terms and Conditions'
                        onClick={() => {
                            window.open(TERMS_LINK, '_blank')
                        }}
                    />
                    <NavbarLink
                        icon={IconShieldCheck}
                        label='Privacy Policy'
                        onClick={() => {
                            window.open(PRIVACY_LINK, '_blank')
                        }}
                    />
                    <Divider sx={{ width: 50, marginTop: 10, marginBottom: 10 }} />
                    <NavbarLink
                        icon={IconLogout}
                        label='Logout'
                        onClick={() => {
                            signOut()
                            router.push('/login')
                        }}
                    />
                </Navbar.Section>
            </Navbar>
        </Flex>
    )
}
