import 'date-fns/format/index.js'
import 'nprogress/nprogress.css'
import '../styles/globals.css'
import 'normalize.css'
import type { AppProps } from 'next/app'
import amplifyconfig from '../src/amplifyconfiguration.json'
import { Amplify } from 'aws-amplify'
import React, { useEffect, useRef, useState } from 'react'
import Router, { useRouter } from 'next/router'
import Head from 'next/head'
import NProgress from 'nprogress'
import { datadogRum } from '@datadog/browser-rum'
import getConfig from 'next/config'
import { IconAlertOctagonFilled, IconCircleXFilled } from '@tabler/icons-react'
import Logger from '../src/logging/Logger'
import { createEmotionCache, Group, MantineProvider, Stack, ThemeIcon } from '@mantine/core'
import Navbar from '../src/components/app/Navbar'
import { Notifications } from '@mantine/notifications'
import dynamic from 'next/dynamic'
import { SurveyContextProvider } from '@/contexts/SurveyContext'
import emporiaTheme from '@/styles/Theme'
import { PoriProvider, BRAND_COLORS } from 'emporia-shared-frontend'

const emporiaLogo = '/images/EmporiaLogoDark.png'

const DONT_RENDER_NAV_ROUTES = [
    '/login',
    '/signup',
    '/scheduling',
    '/forgot_password',
    '/survey',
    '/survey/qualifiedinterview',
    '/survey/qualified',
    '/survey/quotafull',
    '/survey/terminated',
    '/survey/timeout',
    '/survey/partial',
    '/survey/statuscheck',
    '/survey/qualityrejected',
    '/sample/collect',
    '/sample/collect/identify',
    '/sample/collect/execute',
    '/sample/collect/error',
    '/partner-quotas',
]

Amplify.configure(
    { ...amplifyconfig },
    {
        ssr: true,
        Storage: {
            S3: {
                prefixResolver: async ({ accessLevel, targetIdentityId }) => {
                    return '' // amplify-managed S3 buckets automatically add "public/private/protected" prefixes to object paths during upload
                    // to enforce access policies. However, since we're importing an S3 bucket (to enable downloads/uploads across environments) and manually defining its permissions,
                    // these prefixes are not needed. Returning an empty string here allows us to specify the exact upload path, avoiding the
                    // unnecessary prefix pattern.
                },
            },
        },
    }
)
NProgress.configure({ showSpinner: false })

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const amplifyPreConnectTargets = [
    ...amplifyconfig.aws_cloud_logic_custom
        .filter((resource) => resource.name == 'Contacts' || resource.name == 'survey')
        .map((resource) => resource.endpoint),
]

const { publicRuntimeConfig } = getConfig()

const LazyResearchUserRoute = dynamic(() => import('../src/components/app/ResearchUserRoute'))

const LazyBlendedUserRoute = dynamic(() => import('../src/components/app/BlendedCollectionRoute'))

const cache = createEmotionCache({ key: 'css' })

function CoreResearchApp({ Component, pageProps }: AppProps) {
    const router = useRouter()
    const ref = useRef<HTMLDivElement>(null)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [warningMessage, setWarningMessage] = useState<string>()
    const [showNavBar, setShowNavBar] = useState<boolean>(false)
    const showSidebar = !DONT_RENDER_NAV_ROUTES.includes(router.route)
    const isBlendedRoute = router.route.startsWith('/sample') || router.route.startsWith('/third-party-sampler')
    const isSurveyRoute = router.route.startsWith('/survey')
    const handleHideDropdown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setShowMenu(false)
        }
    }

    const handleClickOutside = (event: Event) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setShowMenu(false)
        }
    }

    useEffect(() => {
        Logger.info('Preparing RUM configuration')
        datadogRum.init({
            applicationId: publicRuntimeConfig.DD_RUM_APP_ID,
            clientToken: publicRuntimeConfig.DD_RUM_CLIENT_KEY,
            site: 'us5.datadoghq.com',
            service: 'core-research-app',
            env: publicRuntimeConfig.environment,
            // Specify a version number to identify the deployed version of your application in Datadog
            version: '1.0.0',
            storeContextsAcrossPages: true,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            defaultPrivacyLevel: 'allow',
            trackResources: true,
            trackLongTasks: true,
            // Sends the request id's and allows the stitch up of APM & RUM metrics
            allowedTracingUrls: [publicRuntimeConfig.APP_URL, publicRuntimeConfig.POLIS_URL],
            trackSessionAcrossSubdomains: true,
            useSecureSessionCookie: true,
            usePartitionedCrossSiteSessionCookie: false,
            allowFallbackToLocalStorage: true,
            compressIntakeRequests: true,
        })
        datadogRum.startSessionReplayRecording()
        document.addEventListener('keydown', handleHideDropdown, true)
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true)
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [])

    const innerPageComponent = <Component setWarningMessage={setWarningMessage} {...pageProps} />

    const SurveyAndBlendedRootStyles = () => {
        return (
            <style jsx global>{`
                html,
                body {
                }
            `}</style>
        )
    }

    let finalComponent = null
    if (isBlendedRoute)
        finalComponent = (
            <LazyBlendedUserRoute setWarningMessage={setWarningMessage}>
                <SurveyAndBlendedRootStyles />
                {innerPageComponent}
            </LazyBlendedUserRoute>
        )
    else if (isSurveyRoute)
        finalComponent = (
            <SurveyContextProvider>
                <SurveyAndBlendedRootStyles />
                {innerPageComponent}
            </SurveyContextProvider>
        )
    else
        finalComponent = (
            <LazyResearchUserRoute setWarningMessage={setWarningMessage} setShowNavBar={setShowNavBar}>
                {innerPageComponent}
            </LazyResearchUserRoute>
        )

    const sharedLayout = (
        <Group spacing={0} noWrap>
            {showSidebar && showNavBar && <Navbar />}
            <Stack
                sx={{
                    overflowY: 'auto',
                    width: '100%',
                    height: '100vh',
                    overflowX: 'hidden',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    {warningMessage && (
                        <div
                            style={{
                                backgroundColor: '#2E71D2',
                                width: '100%',
                                height: 60,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ThemeIcon>
                                <IconAlertOctagonFilled size={20} />
                            </ThemeIcon>
                            <p
                                style={{
                                    color: 'white',
                                    fontWeight: '500',
                                    marginBottom: 0,
                                    marginLeft: 10,
                                }}
                            >
                                {warningMessage}
                            </p>
                            <ThemeIcon
                                onClick={() => setWarningMessage('')}
                                style={{
                                    position: 'absolute',
                                    right: 20,
                                    cursor: 'pointer',
                                }}
                            >
                                <IconCircleXFilled size={20} />
                            </ThemeIcon>
                        </div>
                    )}
                    {finalComponent}
                </div>
            </Stack>
        </Group>
    )

    return (
        <>
            <Head>
                <link rel='icon' type='image/png' sizes='32x32' href={'/favicon.jpg'} />
                <link rel='icon' type='image/png' sizes='16x16' href={'/favicon.jpg'} />
                <title>Emporia Research</title>
                <meta name='viewport' content='initial-scale=1.0, width=device-width' />
                <meta
                    name='description'
                    content={
                        'Emporia connects growth minded market researchers to the exact professionals they need for any B2B market research project. Source qualified candidates, automate recruitment and streamline incentive distribution. All in one place.'
                    }
                />
                <meta
                    name={'og:description'}
                    content={
                        'Emporia connects growth minded market researchers to the exact professionals they need for any B2B market research project. Source qualified candidates, automate recruitment and streamline incentive distribution. All in one place.'
                    }
                />
                <meta property='og:image' content={emporiaLogo} />
                <meta name={'og:title'} content={'Emporia Research'} />
                {amplifyPreConnectTargets.map((endpoint, index) => (
                    <link key={`api-${index}`} rel='preconnect' href={endpoint} />
                ))}
                <link rel='preconnect' href='https://fonts.googleapis.com' />
                <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin='anonymous' />
                <link rel='preconnect' href='https://cognito-identity.us-east-1.amazonaws.com' />
                <link rel='preconnect' href='https://widget.intercom.io' />
                <link rel='preconnect' href='https://js.intercomcdn.com' />
                <link rel='preconnect' href='https://session-replay.browser-intake-us5-datadoghq.com' />
            </Head>
            <MantineProvider
                emotionCache={cache}
                // @ts-ignore
                theme={{
                    colorScheme: 'light',
                    fontFamily: 'Inter, Helvetica, Arial, sans-serif',
                    primaryColor: 'purple',
                    colors: {
                        // @ts-ignore
                        customGreys: BRAND_COLORS.GREY,
                        // @ts-ignore
                        purple: BRAND_COLORS.PURPLE,
                    },
                    defaultRadius: 'md',
                    radius: {
                        sm: '0.25rem',
                        md: '0.4rem',
                    },
                    ...emporiaTheme,
                }}
            >
                <PoriProvider>{sharedLayout}</PoriProvider>
                <Notifications position='top-center' />
            </MantineProvider>
        </>
    )
}

export default CoreResearchApp
