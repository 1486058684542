import { v4 as uuidv4 } from 'uuid'
import { QUAL_STUDY } from 'emporia-shared-frontend'

export const getRandomUUID = () => {
    return uuidv4().replace(/-/g, '')
}

export const isBrowser = () => typeof window !== 'undefined'

export const permissionCheck = (userProfile, permissionToCheck) => {
    if (!userProfile) {
        return false
    }
    if (userProfile.hasOwnProperty(permissionToCheck)) {
        return userProfile[permissionToCheck]
    } else {
        return true
    }
}

export const teamPermissionCheck = (team, permissionToCheck) => {
    if (!team) {
        throw new Error('Unknown team to check')
    }
    if (typeof team[permissionToCheck] === 'string') {
        return team[permissionToCheck].toLowerCase() === 'true'
    } else {
        return team[permissionToCheck]
    }
}

export const calcFieldingTime = (feasibility: number) => {
    if (feasibility >= 0.9) {
        return '5'
    }
    if (feasibility >= 0.8) {
        return '6'
    }
    if (feasibility >= 0.7) {
        return '7'
    }
    if (feasibility >= 0.6) {
        return '8'
    }
    if (feasibility >= 0.5) {
        return '9'
    } else {
        return '10+'
    }
}

export const calcBusinessDays = (dDate1, dDate2) => {
    // adjusted code from https://stackoverflow.com/questions/3464268/find-day-difference-between-two-dates-excluding-weekend-days
    let iWeeks,
        iDateDiff,
        iAdjust = 0
    if (dDate2 < dDate1) {
        return -1
    } // error code if dates transposed
    let iWeekday1 = dDate1.getDay() // day of week
    let iWeekday2 = dDate2.getDay()
    iWeekday1 = iWeekday1 == 0 ? 7 : iWeekday1 // change Sunday from 0 to 7
    iWeekday2 = iWeekday2 == 0 ? 7 : iWeekday2
    if (iWeekday1 > 5 && iWeekday2 > 5) iAdjust = 1 // adjustment if both days on weekend
    iWeekday1 = iWeekday1 > 5 ? 5 : iWeekday1 // only count weekdays
    iWeekday2 = iWeekday2 > 5 ? 5 : iWeekday2
    // calculate difference in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
    const weekMillis = 1000 * 60 * 60 * 24 * 7
    const dayMillis = 1000 * 60 * 60 * 24
    iWeeks = Math.floor((dDate2.getTime() - dDate1.getTime() - dayMillis) / weekMillis)
    if (iWeekday1 < iWeekday2) {
        //Equal to makes it reduce 5 days
        iDateDiff = iWeeks * 5 + (iWeekday2 - iWeekday1)
    } else {
        iDateDiff = (iWeeks + 1) * 5 - (iWeekday1 - iWeekday2)
    }

    iDateDiff -= iAdjust // take into account both days on weekend

    return iDateDiff + 1 // add 1 because dates are inclusive
}

export const calculateIncidence = (respondentData) => {
    if (!respondentData || !respondentData.length) {
        return 100
    }
    const dqCandidates = respondentData.filter((data) => data.project_status == 'SurveyTerminated').length
    const startedCandidates = respondentData.filter((data) => data.project_status == 'Started').length
    return (
        ((respondentData.length - dqCandidates - startedCandidates) * 100) / (respondentData.length - startedCandidates)
    )
}

export const recommendIncentive = (studyType: string, loi: number) => {
    let result = 1
    const qualMultiplier = 1
    const quantMultiplier = 0.7
    const incomeMultiplier = 1.25 * loi

    if (studyType === QUAL_STUDY) {
        result = incomeMultiplier * qualMultiplier
    } else {
        result = incomeMultiplier * quantMultiplier
    }
    if (loi < 25) {
        result = result * 1.5
    }
    if (loi >= 25 && loi < 30) {
        result = result * 1.45
    } else {
        result = result * 1.1
    }
    if (loi >= 30 && loi < 50) {
        result = result * 1.3
    } else {
        result = result * 1.2
    }
    result = Math.max(Math.ceil(result), 5)
    return result
}

export const getCurrentTimeZ = () => {
    const now = new Date()
    const year = now.getUTCFullYear()
    const month = String(now.getUTCMonth() + 1).padStart(2, '0')
    const day = String(now.getUTCDate()).padStart(2, '0')
    const hour = String(now.getUTCHours()).padStart(2, '0')
    const minute = String(now.getUTCMinutes()).padStart(2, '0')
    const second = String(now.getUTCSeconds()).padStart(2, '0')
    const millisecond = String(now.getUTCMilliseconds()).padStart(3, '0')
    return `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}Z`
}

export const calculateDateDelta = (dateString1: string, dateString2: string) => {
    const date1 = new Date(dateString1)
    const date2 = new Date(dateString2)
    const timeDiff = Math.abs(date2.getTime() - date1.getTime())
    return Math.ceil(timeDiff / (1000 * 3600 * 24))
}

export const checkKeyQuestion = (studyType, currentAnswer) => {
    return (
        studyType === 'quant' ||
        !('keyQuestion' in currentAnswer.screenerQuestion) ||
        currentAnswer.screenerQuestion.keyQuestion
    )
}

export const terminatingScore = (surveyAnswers, studyType) => {
    try {
        let totalAnswered = 0
        let wrongAnswers = 0
        for (const currentAnswer of surveyAnswers) {
            if (currentAnswer.selectedAnswers) {
                if (currentAnswer.screenerQuestion.questionType === 'select_many') {
                    for (let i = 0; i < currentAnswer.screenerQuestion.screenerAnswers.length; i++) {
                        const valToCheck = currentAnswer.screenerQuestion.screenerAnswers[i]
                        if (
                            valToCheck.answerType === 'reject' &&
                            currentAnswer.selectedAnswers.includes(i.toString())
                        ) {
                            wrongAnswers += 1
                            if (checkKeyQuestion(studyType, currentAnswer)) {
                                return 0
                            }
                            break
                        }
                        if (
                            valToCheck.answerType === 'must_select' &&
                            !currentAnswer.selectedAnswers.includes(i.toString())
                        ) {
                            wrongAnswers += 1
                            if (checkKeyQuestion(studyType, currentAnswer)) {
                                return 0
                            }
                            break
                        }
                    }
                }
                if (currentAnswer.screenerQuestion.questionType === 'select_one') {
                    for (let i = 0; i < currentAnswer.screenerQuestion.screenerAnswers.length; i++) {
                        const valToCheck = currentAnswer.screenerQuestion.screenerAnswers[i]
                        if (valToCheck.answerType === 'reject') {
                            if (currentAnswer.selectedAnswers.includes(valToCheck.answerText)) {
                                wrongAnswers += 1
                                if (checkKeyQuestion(studyType, currentAnswer)) {
                                    return 0
                                }
                            }
                        }
                    }
                }

                if (
                    currentAnswer.screenerQuestion.questionType === 'selection_matrix' &&
                    currentAnswer.selectedAnswers
                ) {
                    const numRows = currentAnswer.screenerQuestion.matrix.rows.length
                    const numSelectedRows = currentAnswer.selectedAnswers.length

                    for (let i = 0; i < numSelectedRows; i++) {
                        const selectedColumn = parseInt(currentAnswer.selectedAnswers[i])
                        if (selectedColumn === undefined) {
                            continue
                        }

                        const rowIndex = i
                        const cellIndex = numRows * selectedColumn + rowIndex
                        const valToCheck = currentAnswer.screenerQuestion.screenerAnswers[cellIndex]

                        if (valToCheck?.answerType === 'reject') {
                            wrongAnswers += 1
                            if (checkKeyQuestion(studyType, currentAnswer)) {
                                return 0
                            }
                            break
                        }
                    }
                }
                totalAnswered += 1
            }
        }
        return (totalAnswered - wrongAnswers) / totalAnswered
    } catch (error) {
        return 1
    }
}

export const shuffleArray = (a) => {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[a[i], a[j]] = [a[j], a[i]]
    }
    return a
}
