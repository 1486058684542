import React, { useReducer, createContext } from 'react'

// Initial state
const initialState = {
    isLoaded: false,
}

// Create reducer
const SurveyReducer = (state, action) => {
    switch (action.type) {
        case 'LOAD_SURVEY_RESPONSE':
            const { survey, ...rest } = action.payload
            return { ...rest, isLoaded: true }
        case 'CLEAR_SURVEY_CONTEXT':
            return { isLoaded: false }
        default:
            throw new Error('Unexpected action to handle for survey reducer')
    }
}

export const loadSurveyResponse = (surveyResponse) => {
    return {
        type: 'LOAD_SURVEY_RESPONSE',
        payload: surveyResponse,
    }
}

export const clearSurveyContext = () => {
    return {
        type: 'CLEAR_SURVEY_CONTEXT',
    }
}

// Context
const SurveyContext = createContext({
    state: null,
    dispatch: (action: any) => {},
})

// Context Provider
export const SurveyContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(SurveyReducer, initialState)
    return <SurveyContext.Provider value={{ state, dispatch }}>{children}</SurveyContext.Provider>
}

export const useSurveyContext = () => React.useContext(SurveyContext)
