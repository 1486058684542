import getConfig from 'next/config'
import { datadogLogs } from '@datadog/browser-logs'
import { isEmpty, isError, isNil } from 'lodash'
import { datadogRum } from '@datadog/browser-rum'
import { isBrowser } from '../utils/utils'

const overallConfig = getConfig()
const { publicRuntimeConfig } = overallConfig
const isDevelopment = !publicRuntimeConfig.isProduction
let targetLogger = console
// If we get an empty publicRuntimeConfig we're probably in a automated test
// if we're not in the browser we're building on the serverside
if (!isEmpty(publicRuntimeConfig) && !publicRuntimeConfig.isDevelopment && isBrowser()) {
    const env = publicRuntimeConfig.environment
    targetLogger.log(`Initializing remote logs for env: ${env} for version 1.0.0`)
    // Initialize log collection
    datadogLogs.init({
        clientToken: publicRuntimeConfig.DD_BROWSER_LOGS_CLIENT_KEY,
        site: 'us5.datadoghq.com',
        service: 'core-research-app',
        env,
        version: '1.0.0',
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['info', 'warn', 'error'],
        forwardReports: 'all',
        trackSessionAcrossSubdomains: true,
        useSecureSessionCookie: true,
        storeContextsAcrossPages: true,
        usePartitionedCrossSiteSessionCookie: true,
    })
    if (!isNil(datadogLogs)) {
        targetLogger.info('Remote logging initialized....sending heartbeat')
        // Setup our global context to ensure that all the fields we expect are sent to the backend
        datadogLogs.setGlobalContext({})
        // @ts-ignore
        targetLogger = datadogLogs.logger
        targetLogger.info(
            `Heartbeat Remote Logs Enabled - Initializing of logging complete for env: ${env} for version 1.0.0....`
        )
    } else {
        targetLogger.error('Unable to initialize remote logger... aborting')
    }
} else {
    targetLogger.debug('Logging initialized locally...')
}

export default {
    log: (...params) => targetLogger.info(...params),
    debug: (...params) => targetLogger.debug(...params),
    info: (...params) => targetLogger.info(...params),
    warn: (msg, context, err, ...params) => {
        targetLogger.warn(msg, (context = null), (err = null), ...params)
        if (isError(err)) datadogRum.addError(err)
        if (isError(context)) datadogRum.addError(context)
    },
    error: (msg, context = null, err = null, ...params) => {
        targetLogger.error(msg, context, err, ...params)
        if (isError(err)) datadogRum.addError(err)
        if (isError(context)) datadogRum.addError(context)
    },
}
